﻿@import (reference) "../Variables.less";
@import (reference) "../Mixins.less";

.bx-slider-news,
.bx-slider {
	padding: 0;
}

.bx-wrapper {
	background: none;
	box-shadow: none;
	border: none;
	margin-bottom: 0;

	.bx-next, .bx-prev {
		width: 50px !important;
		height: 50px !important;
		margin-top: -25px !important;
	}

	.bx-prev, &:hover .bx-prev {
		background: url('data:image/svg+xml;charset%3DUS-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20class%3D%22ionicon%22%20viewBox%3D%220%200%20512%20512%22%3E%3Ctitle%3EChevron%20Back%3C%2Ftitle%3E%3Cpath%20fill%3D%22none%22%20stroke%3D%22%23BCC1BF%22%20stroke-linecap%3D%22round%22%20stroke-linejoin%3D%22round%22%20stroke-width%3D%2248%22%20d%3D%22M328%20112L184%20256l144%20144%22%2F%3E%3C%2Fsvg%3E');
	}

	.bx-next, &:hover .bx-next {
		background: url('data:image/svg+xml;charset%3DUS-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20class%3D%22ionicon%22%20viewBox%3D%220%200%20512%20512%22%3E%3Ctitle%3EChevron%20Forward%3C%2Ftitle%3E%3Cpath%20fill%3D%22none%22%20stroke%3D%22%23BCC1BF%22%20stroke-linecap%3D%22round%22%20stroke-linejoin%3D%22round%22%20stroke-width%3D%2248%22%20d%3D%22M184%20112l144%20144-144%20144%22%2F%3E%3C%2Fsvg%3E');
	}

	.bx-pager {
		bottom: 30px;
		width: 100px;
		left: 50%;
		transform: translateX(-50px);

		.bx-pager-item a {
			background: @slider-gray;
		}

		.bx-pager-item a.active, .bx-pager-item a:hover {
			background: @secondary-2;
		}
	}
}

.home-slider .bx-controls.bx-has-pager {


	@media (min-width: @screen-md-min) and (max-width:@screen-md-max) {
		.bx-pager {
			left: 20px;
			transform: none !important;
		}
	}

	@media (max-width:@screen-sm-max) {
		height: 400px;
		position: absolute;
		width: 100%;
		top: 0;
	}
}

.news-carrousel-container {
	width: 90%;
	margin: 0 auto;

	.bx-wrapper {
		.bx-prev, &:hover .bx-prev {
			background: url('data:image/svg+xml;charset%3DUS-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20class%3D%22ionicon%22%20viewBox%3D%220%200%20512%20512%22%3E%3Ctitle%3EChevron%20Back%3C%2Ftitle%3E%3Cpath%20fill%3D%22none%22%20stroke%3D%22%23273a89%22%20stroke-linecap%3D%22round%22%20stroke-linejoin%3D%22round%22%20stroke-width%3D%2248%22%20d%3D%22M328%20112L184%20256l144%20144%22%2F%3E%3C%2Fsvg%3E');
		}

		.bx-next, &:hover .bx-next {
			background: url('data:image/svg+xml;charset%3DUS-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20class%3D%22ionicon%22%20viewBox%3D%220%200%20512%20512%22%3E%3Ctitle%3EChevron%20Forward%3C%2Ftitle%3E%3Cpath%20fill%3D%22none%22%20stroke%3D%22%23273a89%22%20stroke-linecap%3D%22round%22%20stroke-linejoin%3D%22round%22%20stroke-width%3D%2248%22%20d%3D%22M184%20112l144%20144-144%20144%22%2F%3E%3C%2Fsvg%3E');
		}
	}

	.bx-next {
		right: -40px;
	}

	.bx-prev {
		left: -40px;
	}

	.bx-pager {
		display: none;
	}

	@media (max-width:@screen-xs-max) {
		.item-slider-container {
			width: calc(~"100vw - 30px") !important;
		}
	}

	@media (min-width:@screen-sm) {
		.item-slider-container .item-slide-title {
			font-size: 28px !important;
		}
	}

	@media (min-width:@screen-md) {
		width: 100%;

		.item-slider-container .item-slide-title {
			font-size: 28px !important;
		}
	}

	@media (min-width:@screen-lg) {
		.bx-next {
			right: -50px;
		}

		.bx-prev {
			left: -50px;
		}
	}
}


	.slide-background {
		position: absolute;
		top: -35px;
		right: -35px;
		bottom: -35px;
		left: -35px;
		background-repeat: no-repeat;
		background-position: center;
		background-size: cover;
		overflow: hidden;
		height: 800px;
		width: calc(~'100% + 35px');
		-webkit-filter: blur(35px);
		-moz-filter: blur(35px);
		-o-filter: blur(35px);
		-ms-filter: blur(35px);
		filter: blur(35px);
		filter: url(/Content/blur.svg#blur);
		filter: progid:DXImageTransform.Microsoft.Blur(PixelRadius='35');
		background: none !important;
	}


	.slide-container {
		margin-right: auto;
		margin-left: auto;
		width: 100%;


		a, a:hover, a:active, a:visited, a:focus {
			text-decoration: none;
		}
	}

.slide-image {
	position: relative;
	background-size: cover;
	background-position: 50% 50%;
	width: 100%;
	height: 400px;

	@media (min-width:@screen-md) {
		height: 500px;
	}

	@media (min-width:@screen-lg) {
		height: 780px;
		padding-top: unset;
	}
}

	.slide-details {
		position: absolute;
		bottom: 0;
		right: 0;
		background: @primary-1;
		width: 100%;
		padding: 30px;
		opacity: 0.9;
		padding: 15px;
		line-height: 2.6rem;

		.slider-button-container {
			padding-top: 5px;
			margin-bottom: -5px;

			.btn {
				font-weight: bold;
			}
		}

		@media (min-width: @screen-xs) {
			padding: 40px;
			line-height: 3rem;

			.slider-button-container {
				padding: 10px;
				position: absolute;
				bottom: 0px;
				right: 0px;
				margin-bottom: 0px;
			}
		}

		@media (min-width: @screen-md) {
			padding: 25px;
			width: 440px;
			border-top-left-radius: 6px;
		}
		
		@media (min-width: @screen-lg) {
			padding: 40px;
			width: 500px;
		}
	}

	@media (max-width: @screen-sm-max) {
		.slide-details {
			position: relative;
		}
	}

	.slide-time {
		color: @primary-2;
		font-size: 20px;
		font-weight: bold;

		@media (min-width: @screen-xs) {
			font-size: 22px;
		}

		@media (min-width: @screen-lg) {
			font-size: 24px;
		}
	}

	.slide-title {
		color: @primary-2;
		font-weight: bold;
		font-size: 24px;

		@media (min-width: @screen-xs) {
			font-size: 26px;
		}

		@media (min-width: @screen-md) {
			font-size: 28px;
		}

		@media (min-width: @screen-lg) {
			font-size: 32px;
		}
	}

	.slide-genre {
		color: @secondary-2;
		font-size: 20px;
		font-weight: bold;

		@media (min-width: @screen-xs) {
			font-size: 22px;
		}

		@media (min-width: @screen-lg) {
			font-size: 24px;
		}
	}