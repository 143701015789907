﻿@import (reference) "../Variables.less";
@import (reference) "../Mixins.less";

.agenda {
	margin-top: 20px;
	padding: 20px;
	background: @primary-2;
	list-style: none;
	.border-radius(5px);

	li{
		margin-top:20px;
	}
}

li.agenda-item {
	position:relative;
	color: @primary-1;
	border-bottom: 3px dotted #ddd;
	margin:0;

	a{
		color:inherit;
	}

	.agenda-hover{
		position:relative;
		padding:10px 5px;
		margin:5px;
		&:hover{
			color:@primary-2;
			background-color:@secondary-2;
			.border-radius(5px);

			.icon-movie-hover{
					display: inline-block;
				}
			.icon-movie{
				display: none;
			}
		}
	}

	select{
		width:90%;
		padding: 5px 8px 5px 10px;
		margin-bottom: 20px;
		margin-right:5px;
	}

	&:last-child{
		border:none;
	}
}

.agenda-date {
	float: left;
	max-width: 15%;
	font-size: 20px;
	font-weight: bold;
	padding: 10px;
	padding-left: 0;
	text-align: center;
	border-right: 1px solid #ddd;
}

.agenda-details {
	float: left;
	width: 60%;
	padding: 10px;
}

.agenda-artist {
	font-weight: 300;
	font-size: 16px;
	margin:0;
}

.agenda-title {
	font-weight: 400;
	font-size: 20px;
	margin: 0;
}

.agenda-genre{
	position:absolute;
	top:0;
	right:5px;
	font-size:16px;
	text-align:right;
	color:@secondary-4;
}