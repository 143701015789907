﻿@import (reference) "../../bower_components/bootstrap-less/less/mixins.less";


.transition(@transition) {
	-webkit-transition: @transition;
	-o-transition: @transition;
	-moz-transition: @transition;
	transition: @transition;
}
.img-responsive(@display: block) {
  display: @display;
  max-width: 100%; // Part 1: Set a maximum relative to the parent
  height: auto; // Part 2: Scale the height according to the width, otherwise you get stretching
}

.border-radius(@radius){
	.border-top-radius(@radius);
	.border-bottom-radius(@radius);
	.border-right-radius(@radius);
	.border-left-radius(@radius);
}