﻿@import (reference) "../Variables.less";
@import (reference) "../Mixins.less";

.performance-button, .movie-button {
    text-align: center;
    margin-bottom: 20px;

    a {
        background-color: @secondary-2;
        width: 100%;
        display: block;
        font-weight: 600;
        font-size: 20px;

        &:hover, &:active, &:focus {
            background-color: @yellow-mouseover-color;
        }
    }
}

.performance-button {
        margin-top:0;
    @media(min-width: @screen-sm) {
    margin-top: 30px;
        text-align: right;
    }
}

.movie-button {
    margin-top: 30px;
    @media(min-width: @screen-sm) {
        text-align: left;
    }
}



.home-performances-container {
    margin-bottom: 35px;
    display: flex;
    flex-wrap: wrap;

    .button {
        background-color: @secondary-2;

        &:hover, &:active, &:focus {
            background-color: @yellow-mouseover-color;
        }
    }

    .performance-preview-container, .performance-preview-container .image-container img {
        border-radius: 6px;
    }

    .performance-preview-container {
        padding: 15px 0px;
        height: 100%;
        display: flex;
        flex-direction: column;
        position: relative;

        .performance-label {
            border-top-left-radius: 6px;
            top: 15px;
        }

        .image-container {
            position: relative;
            width: 100%;

            img {
                width: 100%;
                object-fit: cover;
                object-position: 50% 50%;
            }

            .date-container {
                position: absolute;
                bottom: 0;
                left: 0;
                padding: 20px;
                background-color: @primary-1;
                border-bottom-left-radius: 6px;
                border-top-right-radius: 6px;

                .date {
                    color: @primary-2;
                    font-weight: bold;
                }
            }
        }

        .info-container {
            position: relative;
            padding: 15px 0px;
            display: flex;
            flex-direction: column;
            flex-grow: 1;

            .title {
                color: #fff;
                font-weight: bold;
                font-size: 26px;

                @media (min-width: @screen-md) {
                    font-size: 28px;
                }

                @media (min-width: @screen-lg) {
                    font-size: 32px;
                }
            }

            .artist {
                font-size: 22px;
                color: #fff;
            }

            .genre {
                font-size: 22px;
                color: @secondary-2;
                font-weight: 600;
            }

            @media(min-width: @screen-lg) {
                font-size: 24px;
            }

            .button-container {
                width: auto;
                position: relative;
                margin-top: 5px;

                .btn {
                    font-weight: bold;
                }
            }

            @media(min-width: @screen-lg) {
                .button-container {
                    width: auto;
                    position: absolute;
                    right: 10px;
                    bottom: 10px;
                    margin-top: 0px;
                }
            }
        }
    }
}

.homepage-news-container {
    background: #fff;
}

.homepage .homepage-performance-container {
    padding: 20px 15px;
    background: @darkblue;

    h2 {
        color: #fff;
    }
}

.homepage-info-container {
    padding: 20px 15px;
    background: #fff;
}

.homepage {
    h2 {
        font-weight: 700;
        color: @primary-1;
        text-align: center;
        font-size: 32px;
    }
}

.home-items-row {
    display: flex;
    flex-wrap: wrap;

    .item-slider-container {
        height: 100%;
        display: flex;
        flex-direction: column;

        .item-slide-text-container {
            flex-grow: 1;
        }
    }
}