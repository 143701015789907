﻿@import (reference) "../Variables.less";
@import (reference) "../Mixins.less";

.icon {
	width: 1em;
	height: 1em;
	display: inline-block;
	-ms-behavior: url("/Scripts/polyfills/backgroundsize.min.htc");
	background-size: contain;

	svg {
		max-height: 100%;
		max-width: 100%;
		float: left;
	}
}

.icon-user-history, .icon-shoppingcart{
	font-size:28px;
	width:24px;

	.icon-md(){
		font-size:16px;
	}
	
	@media (min-width:@screen-md){
		.icon-md();
	}
}
.icon-shoppingcart{
	height:24px;

	.icon-shoppingcart-md(){
		height: 1em;
	}

	
	@media (min-width:@screen-md){
		.icon-shoppingcart-md();
	}
}


.icon-filter{
	height:22px;
}

.icon-twitter, .icon-facebook, .icon-instagram, .icon-twitter-hover, .icon-facebook-hover, .icon-instagram-hover{
	font-size: 28px;
}

.icon-twitter-hover, .icon-facebook-hover, .icon-instagram-hover, icon-movie-hover{
	display:none;
}
.icon-movie, .icon-movie-hover{
	height:12px;
}

.inline-text-icon {
	width:1em;
	height:1em;
	margin-top:-0.05em;
	margin-right:0.4em;
}

.glyphicon-triangle-bottom{
	font-size:10px;
}

li li .glyphicon-triangle-bottom{
	display:none;
}