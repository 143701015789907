﻿@import (reference) "../Variables.less";

#countdown-modal {
    display: block;
    position: fixed;
    z-index: 99999;
    padding-top: 20vh;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgb(0,0,0);
    background-color: rgba(0,0,0,0.4);    

    &.hide {
        display: none;
    }
}

.countdown-modal-content {
    background-color: white;
    margin: auto;
    padding: 30px;
    width: 98%;
    border: 4px solid @secondary-2;
}

.countdown-timer{
    color: @secondary-2;
    font-size: 40px;
    font-weight: bold;
}

.modal-heading{
    margin-bottom: 20px;
    color: @modal-heading-color;
    font-size: 30px;
    font-weight: bold;
    line-height: 1.2;
}

.modal-description{
    color: black;
    font-size: 18px;
    margin-bottom: 20px;
}

.modal-date{
    font-weight: bold;
}

.modal-close-button{
    font-size: 18px !important;
}

@media(min-width: 768px){
    .countdown-timer {
        font-size: 64px;
    }
}

@media(min-width: 992px){
    .countdown-modal-content {
        width: 60%;
    }
}

@media(min-width: 1200px){
    .countdown-modal-content {
        width: 40%;
    }

    #countdown-modal{
        padding-top: 40vh;
    }    
}