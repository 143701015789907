﻿@import (reference) "../Variables.less";
@import (reference) "../Mixins.less";

.menu-search{
	padding: 0 15px;
	padding-bottom: 15px;
}


.search-button {
	position: absolute;
	right: 0px;
	cursor: pointer;
	line-height:60px;
	text-align:center;
}

.nav-search-form {
	background-color: @primary-1;
	right: 0px;

	.close-button {
		float: left;
		height: 40px;
		width: 40px;
		cursor: pointer;

		.close-icon {
			margin-top: 10px;
		}
	}

	input {
		float: left;
		height: 40px;
		width: ~"calc(100% - 40px)";
		border: none;
		margin-bottom: 15px;
		padding-left: 5px;
	}

	button {
		float: right;
		line-height: 40px;
		width: 40px;
		height: 40px;
		border: none;
		background-color: white;

		.icon-search {
			filter: invert(100%);
		}
	}

	@media (min-width: @screen-sm) {
		z-index: 2;
		position: absolute;
		padding: 10px 0px;
		width: 300px;
		display: none;
		vertical-align: middle;
		text-align: center;
		width: 500px;

		input {
			width: ~"calc(100% - 80px)";
			margin-bottom: 0;
		}
	}
}

.search-form, .program-search-form {

	input[type="text"] {
		height: 41px;
		width: calc(~'100% - 85px');
		background: #fff;
		float: left;
		padding: 0 10px;
		font-family: roboto condensed, arial, sans-serif;
		font-weight: 300;
		font-size: 20px;
	}

	input[type="submit"] {
		padding: 0;
		height: 41px;
		font-family: roboto condensed, arial, sans-serif;
		color: #fff;
		background: @secondary-1;
		width: 85px;
		font-size: 20px;
		font-weight: 400;
		text-transform: uppercase;
		cursor: pointer;
		border: 0;
		outline: none;

		&:hover{
			background: @secondary-2;
		}
	}
}

.search-form{
	height: 101px;
	margin-bottom: 20px;
	padding: 0 20px;
	border-radius: 5px;
	background: @primary-1;

	input[type="text"] {
		margin-top: 30px;
		border: 0;
		outline: none;
	}
	
	input[type="submit"] {
		margin: 30px 0 0 0;
		}
}

.program-search-form{
	padding-bottom: 15px;
}