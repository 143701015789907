﻿@import (reference) "../Variables.less";
@import (reference) "../Mixins.less";


.tweets {
	margin-top: 20px;
	padding: 20px;
	padding-top:0;
	background: @primary-2;
	list-style: none;

	h3{
		margin:0;
	}

	a{
		color: @secondary-3;
	}
}

.tweet-item {
	margin: 10px 0;
	padding: 10px;
	color:@secondary-3;
	border-bottom: 3px dotted #ddd;
}

.tweet-header{

	height:44px;

	h3{
		float:left;
		width:90%;
	}

	.tweet-follow{
		float:right;
	}
}

.tweet-date {
	float: left;
	max-width: 5%;
	font-size: 20px;
	font-weight: bold;
	padding: 10px;
	padding-left: 0;
	text-align: center;
	border-right: 1px solid #ddd;
}

.tweet-user{
	float:left;
	width:25%;
	padding:10px;
	border-right: 1px solid #ddd;
}

.tweet-user-name {
	font-weight: 300;
	font-size: 16px;
}

.tweet-display-name {
	font-weight: 400;
	font-size: 19px;
	margin: 0;
}
.tweet-details {
	float: left;
	width: 70%;
	padding: 10px;
	color:@secondary-4;
}
