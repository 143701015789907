﻿@import (reference) "../Variables.less";
@import (reference) "../Mixins.less";

.performance-filter{
	font-size:24px;
	line-height:54px;

	select{
		width:100%;
	}
}

.program-performance-filter{
	font-size:24px;
	padding-bottom: 15px;

	select{
		width:90%;
		height: 41px;
		margin-right:5px;
	}
}

.performance-month-year {
	position: relative;
	font-weight: 500;
	font-size: 24px;
	color: #fff;
	margin-top: -3px;
	margin-bottom: 25px;
	padding: 10px;
	text-align: center;
	text-transform: uppercase;
	background-color: @primary-1;
	.border-radius(2px);

	.triangle {
		position: absolute;
		top: 100%;
		left: calc(~' 50% - 30px');
	}
}

.performance-preview-hover{
	padding: 20px 0;
	margin:5px 0;
	.border-radius(5px);

	&:hover{
		background-color:@secondary-2;
		color:@primary-2;

		a{
			color:@primary-2;
		}
	}
}

.performance-preview {
	min-height: 200px;
	margin: 0;
	border-bottom: 3px dotted #ddd;

	.performance-daystring {
		font-size: 24px;
		text-align: center;
		margin: 0;
		text-transform: uppercase;
	}

	.performance-day {
		font-size: 40px;
		font-weight: bold;
		text-align: center;
		margin: 0;
	}

	.performance-month {
		font-size: 24px;
		text-align: center;
		margin: 0;
	}


	.performance-image {
		position: relative;
	}

	.performance-title {
		font-size: 24px;
	}

	.performance-genre {
		font-size: 16px;
	}

	.performance-date {
		position: relative !important;
	}

	a, a:active, a:visited, a:focus, a:hover {
		text-decoration: none;
	}
}

.performance-time {
	font-size: 14px;
}

.performance-artist {
	font-size: 20px;
}

.performance-label {
	position: absolute;
	background: @performancelabel-color;
	color: #fff;
	padding: 15px;
	font-size: 18px;
	font-weight: 700;
	opacity: 0.9;
	top: 0;
	z-index: 1;
}

.performance-container {
	.performance-top, .performance-middle {
		margin-top: 20px;
		color: @primary-1;
		background-color: @primary-2;
	}

	.performance-bottom {
		margin-top: 20px;
		padding: 20px;
		background-color: @primary-2;
	}

	.performance-date {
		position: absolute;
		bottom: 0;
		left: 0;
		height: 56px;
		line-height: 56px;
		padding: 0 20px;
		color: #fff;
		background-color: @primary-1;
		border-top-right-radius: 6px;
	}

	.performance-image {
		position: relative;
		margin-bottom: 15px;
		background-size: cover;
		width: 100%;
		padding-top: 56.25%;
		position: relative;
		background-position: 50% 50%;

		.slide-image-sm() {
			height: 330px;
			width: 100%;
		}

		@media (min-width:@screen-sm) {
			.slide-image-sm();
		}
	}

	.performance-details {
		position: relative;
		padding: @default-padding;
		padding-top: 0;
		min-height: 100px;

		.social-media {
			position: absolute;
			right: 15px;
			bottom: 15px;
		}
	}

	.performance-artist {
		font-size: 26px;
		margin: 0;
	}

	.performance-title {
		font-size: 35px;
		line-height: 34px;
		font-weight: bold;
		margin: 0;
	}

	.performance-genre {
		font-size: 24px;
		line-height: 24px;
		color: @secondary-2;
		font-weight: 600;
		margin: 0;
	}
}
.seating-method{
	padding:@default-padding;
	min-height: 100px;
}

.active-seating-method{
	background-color: @secondary-2;
	.border-radius(5px);
}

.twitter-share-button{
	vertical-align:bottom;
}

.seat-selection {
	position: relative;
	height: 650px;
	width: 750px;
	background: url('/Content/Images/stoelen-plan.png') 0 0 no-repeat;
}

.actioncode-container{
    padding-top: 20px;
}

.trailer-container {
	position: relative;
	display: block;
	width: 100%;
	padding: 0;
	overflow: hidden;
}

.trailer-container::before {
	display: block;
	content: "";
	padding-top: 56.25%
}

.trailer-container * {
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	width: 100%;
	height: 100%;
	border: 0;
}

.performance-poster {
	width: 100%;
	margin-top: 30px;
}

.seat {
	position: absolute;
	height: 12px;
	width: 12px;
	top: 0;
	left: 0;
	border: 1px solid #808080;
	-webkit-border-radius: 6px;
	-webkit-background-clip: padding-box;
	-moz-border-radius: 6px;
	-moz-background-clip: padding;
	border-radius: 6px;
	background-clip: padding-box;
}

.available {
	background-color: #9ba5af;
	background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/PjxzdmcgeG1sbnM9Imh0d…0iMSIgaGVpZ2h0PSIxIiBmaWxsPSJ1cmwoI2xlc3NoYXQtZ2VuZXJhdGVkKSIgLz48L3N2Zz4=);
	background-image: -webkit-linear-gradient(-90deg, #cacfd4 0%, #9ba5af 100%);
	background-image: -moz-linear-gradient(-90deg, #cacfd4 0%, #9ba5af 100%);
	background-image: -o-linear-gradient(-90deg, #cacfd4 0%, #9ba5af 100%);
	background-image: linear-gradient(180deg, #cacfd4 0%, #9ba5af 100%);
	border: 1px solid #6c747b;
}

.reserved {
	background-color: #861220;
	border-color: #741221;
	background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/PjxzdmcgeG1sbnM9Imh0d…0iMSIgaGVpZ2h0PSIxIiBmaWxsPSJ1cmwoI2xlc3NoYXQtZ2VuZXJhdGVkKSIgLz48L3N2Zz4=);
	background-image: -webkit-linear-gradient(-90deg, #c58f96 0%, #861220 100%);
	background-image: -moz-linear-gradient(-90deg, #c58f96 0%, #861220 100%);
	background-image: -o-linear-gradient(-90deg, #c58f96 0%, #861220 100%);
	background-image: linear-gradient(180deg, #c58f96 0%, #861220 100%);
}

.unavailable {
	background: #dadada;
	border-color: #dadada;
	background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/PjxzdmcgeG1sbnM9Imh0d…0iMSIgaGVpZ2h0PSIxIiBmaWxsPSJ1cmwoI2xlc3NoYXQtZ2VuZXJhdGVkKSIgLz48L3N2Zz4=);
	background-image: -webkit-linear-gradient(-90deg, #eeeeee 0%, #dadada 100%);
	background-image: -moz-linear-gradient(-90deg, #eeeeee 0%, #dadada 100%);
	background-image: -o-linear-gradient(-90deg, #eeeeee 0%, #dadada 100%);
	background-image: linear-gradient(180deg, #eeeeee 0%, #dadada 100%);
}

.selected{
	background: #0084e4;
	border-color: #6c747b;
	background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/PjxzdmcgeG1sbnM9Imh0d…0iMSIgaGVpZ2h0PSIxIiBmaWxsPSJ1cmwoI2xlc3NoYXQtZ2VuZXJhdGVkKSIgLz48L3N2Zz4=);
	background-image: -webkit-linear-gradient(-90deg, #9ac2e5 0%, #0084e4 100%);
	background-image: -moz-linear-gradient(-90deg, #9ac2e5 0%, #0084e4 100%);
	background-image: -o-linear-gradient(-90deg, #9ac2e5 0%, #0084e4 100%);
	background-image: linear-gradient(180deg, #9ac2e5 0%, #0084e4 100%);
}