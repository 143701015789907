﻿@import (reference) "../Variables.less";
@import (reference) "../Mixins.less";

.header-container {
	width: 100%;
	height: 55px;
	padding: 0 10px;

	@media(min-width: @screen-sm) {
		padding: 0 30px;
		height: 75px;
	}

	@media(min-width: @screen-md) {
		height: 95px;
	}

	@media(min-width: @screen-lg) {
		padding: 0 100px;
	}
}

.header {
	position: relative;
	padding-top: 10px;
	padding-bottom: 10px;
	background-color: #f4f4f4;

	@media(min-width: @screen-sm) {
		padding-top: 20px;
		padding-bottom: 20px;
	}

	@media(max-width: @screen-sm) {
		margin-bottom: 0;
		z-index: 10000;
		top: 0;
	}
}

.top-header-logo {
	float: left;

	@media(max-width: @screen-md) {
		width: 135px;

		img {
			width: 100%;
		}
	}
}

.top-header-item {
	color: @primary-1;
	line-height: 55px;
	padding-left: 50px;

	.icon {
		margin-right: 5px;
	}

	.icon-arrow-down-blue, .icon-arrow-up-blue {
		margin-left: 5px;
		height: 10px;
	}
}

.phone-number {
	font-size: 18px;

	@media(min-width: @screen-md) {
		font-size: 24px;
	}

	@media(max-width: @screen-sm-max) {
	}
}

.e-mail {
	padding-top: 19px;
	font-size: 20px;
	width: 300px;
}

.route {
	padding-top: 19px;
	font-size: 20px;
	width: 200px;
}

.shopping-cart {
	padding-top: 19px;
	font-size: 20px;
	width: 135px;
}

.account {
	padding-top: 19px;
	font-size: 20px;
	width: 120px;
}
