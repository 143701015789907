﻿@import (reference) "../Variables.less";
@import (reference) "../Mixins.less";

.item-slide-image {
	width: 100%;
	background-size: cover;
	background-position: 50% 50%;
	border-radius: 6px;
	padding-top: 56.25%;
}

.item-slide-date {
	font-size: 22px;
	color: @secondary-2;
	font-weight: 600;
}

.custom-homepage-item {
	padding-top: 25px;	

	&.text-align-center {
		text-align: center;
	}
}

.item-slide-text-container {
	margin: 0 auto;
	width: 100%;
	padding: 25px 0px;

	.btn-primary {
		background-color: @secondary-2;

		&:hover, &:active, &:focus {
			background-color: @yellow-mouseover-color;
		}
	}
}
.item-slide-title {
	font-weight: bold;
	color: @primary-1;
	font-size: 26px;

	@media (min-width: @screen-md) {
		font-size: 28px;
	}

	@media (min-width: @screen-lg) {
		font-size: 32px;
	}
}
.item-slide-text {
	padding: 15px 0;
	color: #000;
}