﻿@import (reference) "../Variables.less";
@import (reference) "../Mixins.less";


body {
	background: #f4f4f4;
	font-family: 'Roboto Condensed', sans-serif;
	font-size: 15px;
	color: @default-text-color;

	.body-lg() {
		font-size: 18px;
	}

	@media (min-width:@screen-lg) {
		.body-lg();
	}

	&.home {
		background: #fff;
	}
}

.header-bg {
	background: #f4f4f4;
}

p{margin: 0;}

h3{
	margin-top:0;
}

h4{
	color: @primary-1;
	font-weight:bold;
}

/* Set padding to keep content from hitting the edges */
.body-content {
	padding-left: 15px;
	padding-right: 15px;
}

/* Override the default bootstrap behavior where horizontal description lists 
   will truncate terms that are too long to fit in the left column 
*/
.dl-horizontal dt {
	white-space: normal;
}

/* Set width on the form input elements since they're 100% wide by default */
input,
select,
textarea {
	color: @primary-1;
	max-width:100%;
/*
	.inputs-lg(){
		max-width: 280px;
	}
	
	@media (min-width:@screen-lg){
		.inputs-lg();
	}
	*/
}

a, a:hover, a:visited,a:active,a:focus{
	color: @primary-1;
}

 .page-container{
	position:relative;
	background-color: #f4f4f4;
}

.homepage {
	padding-top: 0;
	margin: 0px;
	width: 100%;
}

.homepage-container {
	padding: 20px 15px;

	h1{
		text-align: center;
		margin-bottom: 30px;
	}
}

.contentpage{
	padding-top:20px;
}

.content-container{
	padding-top: 150px;

	.agenda{
		margin-top:0;
	}
}

.content-block{
	background: @primary-2;
	padding:@default-padding;
	.border-radius(5px);
}

.background-banner {
	position:absolute;
	overflow:hidden;
	height:200px;
	width: 100%;
	background-size: cover;

	h1{
		color:@primary-1;
		line-height:150px;
		height:150px;
		font-size: 50px;
		margin:0;
	}
}

.hasBackGround h1{
	color:@primary-2 !important;
}

.no-banner{
	padding-top:20px;
}


.preview-item{
	background: @primary-2;
	margin-bottom:15px;
	img{
		float:left;
		padding:15px;
	}

	.preview-content{
		float:left;
		padding:@default-padding;
		width:55%;

		h3{
			margin-top:0;
			color:@primary-1;
		}
	}

	.preview-item-lg(){
		.preview-content{
			width:62%;
		}
	}

	
	@media (min-width:@screen-lg){
		.preview-item-lg();
	}
}

.full-width{
	width:100% !important;
}

.triangle{
	width: 0;
	height: 0;
	border-style: solid;
	border-width: 15px 15px 0 15px;
	border-color: @primary-1 transparent transparent transparent;
}

.mobile-content{
	@media(min-width:@screen-lg){
		display:none;
	}
}

.desktop-content{
	display:none;
	@media(min-width:@screen-lg){
		display:block;
	}

}

.rounded-corners {
	.border-radius(5px);
}

.large-text {
	font-size: 22px;
}

.text-bold {
	font-weight: bold;
}

.text-underline {
	text-decoration: underline;
}