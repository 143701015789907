﻿@import (reference) "../Variables.less";
@import (reference) "../Mixins.less";

.order-tickets{
	font-size:17px;

	input{
		text-align:center;
	}
}

.shoppingcart-container {
	font-size: 16px;

	.shoppingcart-row {
		padding-bottom: 2px;
	}

	p {
		margin: 0;
	}

	.title {
	}

	.artist {
		font-style: italic;
	}

	.ticket-date {
		color: #aaa;
		font-style: italic;
	}

	.ticket-type {
	}

	input[type="submit"] {
		color: #fff;
		border: none;
		background-color: @secondary-1;
		padding: 6px 12px;

		border-radius: 4px;
		display: inline-block;
		margin-bottom: 0;
		font-weight: normal;
		text-align: center;
		vertical-align: middle;
		-ms-touch-action: manipulation;
		touch-action: manipulation;
		cursor: pointer;
		background-image: none;
		white-space: nowrap;
		-moz-user-select: none;
		-ms-user-select: none;
		-webkit-user-select: none;
		user-select: none;

		&:active{
			border-color: #204d74;
			outline: 0;
			box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
		}


		&:hover{
			background-color:@secondary-2;
		}
	}
}