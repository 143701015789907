﻿@import (reference) "../Variables.less";
@import (reference) "../Mixins.less";


.footer-container {
	background-color: @primary-1;
	margin-top: 288px;

	@media (min-width:@screen-sm) {
		margin-top: 148px;
	}
}

.footer-inner-container {
	position: relative;
	color: white;
	padding: 20px 15px;

	.title-container {
		padding-bottom: 25px;
		font-size: 20px;
		font-weight: bold;
		min-height: 53px;
	}

	.bottom-footer .title-container {
		display: none;
	}

	.text-container {
		font-size: 18px;
		line-height: 1.5;
		padding-bottom: 25px;


		a {
			color: white;
		}

		.social-media-icon {
			margin-right: 30px;
			text-decoration: none;
		}
	}
}


.footer-block {
	margin-bottom: 20px;
}

.footer-logo {
	margin-top: -288px;

	@media (min-width:@screen-sm) {
		margin-top: -148px;
	}
}

.footer-links {
	color: #B9BFD2;
	font-size: 16px;

	a {
		color: #B9BFD2;
		margin-right: 20px;
	}

	@media (min-width:@screen-md) {
		float: right;
	}
}

.newsletter-btn {
	background-color: @secondary-2 !important;
	margin-left: 15px !important;
	margin-bottom: 45px !important;
	margin-top: 45px !important;
}