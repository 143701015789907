﻿.aspect-16by9 {
    width: 100%;
    padding-top: 56.25%;
    position: relative;

    > img {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        height: 100%;
        width: 100%;
        object-fit: cover;
    }
}
