﻿@import (reference) "../Variables.less";
@import (reference) "../Mixins.less";


.header-item{
	font-weight:bold;
}


a.btn {
	color:@primary-2;
}

.btn-lg {
	font-size: 18px;
	padding: 13px 16px;
}

.btn-primary{
	border:none;
	background-color: @secondary-1;

	&:focus, &.focus, &:active, &.active, .open > .dropdown-toggle.btn-primary{
		background-color: @secondary-1;
	}

	&:hover {
		background-color: @secondary-2;
	}
}


.btn-max {
	width: 100%;
}
.field-row{
	padding: 0 0 10px 0;
	zoom: 1;
	font-size:16px;
}

.field-row label{
	display: block;
	float: left;
	width: 100%;
	padding: 5px 0;
}

.field-row input[type='text'], .field-row input[type='email'], .field-row input[type='password'], .field-row input[type='number'], .field-row input[type='datetime'], .field-row textarea, .field-row select {
	display: block;
	width: 100%;
	padding: 5px 10px;
	border: 1px solid #dbdbdb;

	&.input-validation-error{
		border:1px solid red;
	}
}

.error-message{
	color:red;
}

.field-validation-error{
	color: red;
}
