﻿@import (reference) "../Variables.less";
@import (reference) "../Mixins.less";

.newsletter-signup{
	input[type="text"], input[type="email"]{
		width:100%;
		margin-top: @default-padding;
		padding: 15px;
		border:0;
		background-color: #F4F4F4;
	}

	input[type="submit"]{
		margin-top:@default-padding;
	}

	.street{
		display:none;
	}
}

.g-recaptcha {
	margin-top: 15px;
}