﻿@import (reference) "../Variables.less";
@import (reference) "../Mixins.less";

@media only screen and (min-width: 768px) {
	.dropdown:hover > .dropdown-menu {
		display: block;
	}

	.nav > li:nth-child(4) a{
		padding-left:0;
	}

	.navbar-default .navbar-nav {
		> li.dropdown:hover, > li.dropdown:active, > li.dropdown:focus {
			> a {
				color: @secondary-2;

				> .icon-arrow-down-white {
					display: none;
				}
				> .icon-arrow-up-yellow {
					display: inline-block;
				}
			}
		}
	}

	.dropdown-menu .dropdown-menu{
		background-color: @primary-1;
		left: 80px;
	}
}

.navbar-default .navbar-nav {
	> li.dropdown.open {
		> a {
			color: @secondary-2;

			> .icon-arrow-down-white {
				display: none;
			}

			> .icon-arrow-up-yellow {
				display: inline-block;
			}
		}
	}
}

.navbar {
	margin-bottom: 0;
	z-index: 10000;

	@media (min-width:@screen-sm) {
		top: 0;
		position: sticky;
	}
}

.nav > li > a{
	padding: 10px 25px;
}

li.dropdown a .icon-arrow-up-yellow {
	display: none;
}

.navbar-nav {
	margin: 0 -15px;
}

.navbar-nav .open .dropdown-menu {
	background-color: @secondary-1;

	> li > a{
		color:@primary-2;
	}
}

.navbar-collapse{
	position:relative;
}

.navbar-default .navbar-nav > li > a:hover, .navbar-default .navbar-nav > li > a:focus {
	color: @secondary-2;
}
.navbar-default .navbar-nav > li > a:focus {
	color: @primary-2;
}

.navbar-default {
	background-color: @primary-1;
	border: 0;
	border-radius: 0;

	.navbar-collapse {
		border: 0;
	}

	.navbar-brand, .navbar-nav > li > a {
		color: @primary-2;
		padding-top: 20px;
		padding-bottom: 20px;

		&:active, :focus {
			color: @primary-2;
		}
	}

	.navbar-nav .open .dropdown-menu {
		background-color: @secondary-1;

		> li > a, .logout-button {
			color: @primary-2;
			line-height: 40px;
		}
	}

	.logout-button {
		color: @primary-2;
		line-height: 40px;
		background-color: @secondary-1;
		border: none;
		width: 100%;
		text-align: left;
		padding-left: 25px;
	}
}

.navbar-default .navbar-nav > .open > a,
.navbar-default .navbar-nav > .open > a:hover,
.navbar-default .navbar-nav > .open > a:focus {
	background-color: @primary-1;
	color: @secondary-2;
}

.dropdown {
	padding: 0;

	.icon{
		//height:10px;
	}

	//.icon-arrow-down-white, .icon-arrow-up-yellow{
	//	height: 9px;
	//}
}

.icon-arrow {
	height: 10px;
}

.dropdown-menu {
	background-color: @secondary-1;
	margin:0;
	padding:0;
	border:0;
	border-radius:0;

	> li > a {
		color: @primary-2;
		line-height:40px;

		&:hover, &:focus {
			background-color: @primary-1;
			color: @primary-2;
		}
	}
}


.nav-buttons {
	position: absolute;
	top: 0;
	right: 15px;
	height: 60px;

	@media(min-width:@screen-sm) {
		top: 5px;
	}
}


.menu-toggle {
	font-size: 20px;
	line-height: 75px;

	.menu-icon {
		width: 30px;
		vertical-align:middle;
	}

	&:active, &:focus, &:hover {
		text-decoration: none;
	}

	@media(min-width:@screen-sm) {
		margin-left: 0px;
	}
}

.navbar-default .navbar-nav > li.menu-phone-number > a {
	background-color: #f4f4f4;
	color: @primary-1;
	font-size: 22px;
}

.user-menu-container {
	
	.user-menu-items {
		padding: 0;
		position: absolute;
		left: 0;
		top: 45px;
		width: 100%;
		list-style: none;
		font-size: 14px;
		border: 1px solid #ccc;
		background: @primary-2;
		z-index: 10001;

		a, input[type="submit"] {
			display: block;
			background: none;
			border: none;
			height: 40px;
			line-height: 40px;
			padding: 3px 20px;
			color: #333;
			width: 100%;
			text-align: left;
			text-decoration: none;

			&:hover {
				background-color: #f4f4f4;
			}
		}
	}

	> ul {
		padding: 0;
		margin: 0;

		> li {
			list-style: none;
			float: left;
			margin-right: 20px;

			&:last-child {
				margin: 0;
			}
		}
	}

	form {
		height: 40px;
		line-height: 40px;
	}

	.user-menu-container-lg() {
		position: relative;

		.user-menu-items {
			width: auto;
		}
	}

	@media (min-width:@screen-md) {
		.user-menu-container-lg();
	}
}